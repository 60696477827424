.grid-container {
	display: grid;
	grid-template: repeat(25, 35px) / 50px 1fr;
	margin-top: 25px;
}
.iclass-spinner {
	position: absolute !important;
	top: 50px;
	z-index: 10;
	width: 80%;
	height: calc(100vh - 50px);
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	background-color: rgba(255, 255, 255, 0.7);
}
.iclass-grid-header {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
.iclass-time {
	color: #017794;
	font-size: 1.1rem;
	margin-bottom: 0;
}
.iclass-time-b {
	grid-column: 2;
	background-color: rgb(229, 228, 255);
	border-left: 3px solid #017794;
	padding-left: 5px;
	box-shadow: 0px 0px 3px -2px #000000;
}
.iclass-available-block {
	grid-column: 2;
	background-color: white;
	border-left: 3px solid #017794;
	padding-left: 5px;
	box-shadow: 0px 0px 3px -2px #000000;
}
.iclass-available-block:hover {
	cursor: pointer;
	box-shadow: 0px 0px 4px -2px #000000;
}
.iclass-time-option-even {
	position: relative;
	bottom: 12px;
}
.iclass-time-option-odd {
	position: relative;
	bottom: 8px;
}
.iclass-selected-date {
	font-size: 1rem;
}
