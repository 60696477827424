.radio-group-div {
	display: flex;
	justify-content: center;
}
.radio-group {
	align-items: center;
	/* width: 60%; */
}
.tipify-submit-button {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-content: flex-end;
}
.typify-top-save-buttons {
	margin-left: 4px;
	margin-right: 4px;
}
.typify-top-info-buttons {
	margin-left: 10px;
	margin-right: 10px;
}
.typify-top-buttons {
	width: 70%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 40px;
}
.typify-comments {
	background-color: aliceblue;
	padding: 10px;
	display: flex;
}
.typify-reconnect-message {
	width: 100%;
	justify-content: center;
	display: flex;
	padding: 0%;
}
.typify-reconnect-input {
	width: 85%;
}
.typify-row {
    justify-content: space-between;
}
.new-typify-row {
    display: flex;
    justify-content: space-between;
}
.new-typify-row-item {
    display: flex;
    width: 30%;
    justify-content: space-between;
}
.new-typify-row-item-input {
    width: 85%;
}
.width-fix-typify {
    width: 208px !important;
}
.center-buttons {
    display: flex;
    justify-content: center;
}
.typify-hide-field {
    display: none !important;
}
.typify-rut-field {
    width: 150px !important;
}
.typify-show-extra-btn {
    margin-top: -15px !important;
    margin-bottom: 15px !important;
}
